










































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class FilterDialog extends Vue {
    @Prop() filter;
    @Prop() header;
    @Prop() filterFields;
    @Prop() fieldRules;
    @Prop() filterExpressions;
    @Prop() expressionRules;
    @Prop() searchLabel;
    @Prop() searchRules;

    apply() {
        this.$emit('apply', this.$refs.form);
    }

    clear() {
        this.$emit('clear', this.$refs.form);
    }
}
